import React, { Component } from "react";
import "./App.scss";
class App extends Component {

  render() {
    return (
      <>
        <p>
          Eric Njuki 
          <br/>
          Currently Software Developer at <a href="https://www.aphl.org">APHL</a> 
          <br/>
          Based in Nairobi, Kenya
        </p>
        <p>
          <a href="https://twitter.com/ericnjuki">twitter</a>
          &nbsp;
          <a href="https://github.com/ericnjuki">github</a>
        </p>
      </>
    );
  }
}

export default App;
